body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-family: Lato, sans-serif;
  /*font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;*/
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#hover-black:hover{
  color: #161E16;
}

.search-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.docsSection{
  margin-bottom: 40px;
}

.docsHeader{
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
}

.form-nosubmit-search {
  color: #555;
  display: flex;
  padding: 2px;
  /*border: 1px solid currentColor;*/
  border-radius: 4px;
  /*margin: 0 0 30px;*/
}

input[type="search"] {
  border: 1px solid #F0F0F0;
  background: transparent;
  margin: 0;
  padding: 8px 8px;
  font-size: 14px;
  color: inherit;
  /*border: 1px solid transparent;*/
  border-radius: inherit;
}

input[type="search"]::placeholder {
  color: #BCBCC2;
}

input[type="search"]:focus {
  box-shadow: 0 0 4px 0 #5499F8;
  border-color: #5499F8;
  outline: none;
}

form.nosubmit-search {
  border: none;
  padding: 0;
}

input.nosubmit-search {
  /*border: 1px solid #555;*/
  width: 100%;
  padding: 8px 4px 8px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.projectSidebarBlock:hover{
  background-color: #5499F8;
  color: white;
}

.projectToolbarIcons{
  cursor: pointer;
  font-size: 16px;
}

.googleSignInBtn{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  border-radius: 1px;
  transition: background-color .218s,border-color .218s,box-shadow .218s;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.googleSignInText{
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  color: white;
  font-weight: 500;
  letter-spacing: .21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
  font-size: 16px;
  line-height: 48px;
  font-family: Roboto,arial,sans-serif;
}

/*TOGGLE RESPONSIVE DESIGNS HERE*/
@media only screen and (min-width: 992px) {
    #landingPageBrowser{
        display: block;
    }

    #landingPageMobile{
        display: none;
    }

    .rightMenuItem{
      text-align: right;
    }
}

@media only screen and (max-width: 992px) {
    #landingPageBrowser{
      display: none;
    }

    #landingPageMobile{
      display: block;
    }

    .rightMenuItem{
      text-align: left;
    }
}

#navbarIcon > a > img{
  /*Full height: 293px*/
}

.inline-icon {
  /*vertical-align: bottom;*/
  font-size: 12px;
  line-height: 20px;
}

.wordwrap{
  word-break: break-all;
  word-wrap: break-word;
}

.wordwrap2{
  white-space: pre-wrap;      /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  /*white-space: -pre-wrap;     !* Opera <7 *!*/
  white-space: -o-pre-wrap;   /* Opera 7 */
  word-wrap: break-word;      /* IE */
}

.ellipses{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropShadow{
  filter: drop-shadow(5px 5px 5px #F0F0F0);
  z-index: -11;
  position: relative;
}

#useCaseMenuItem:hover{
  background-color: #161E16;
  color: white;
  font-weight: 700;
}

.mobileSidebar{
  background-color: #EE685F;
  /*background-color: #FDEFEF;*/
  /*background-color: red;*/
  min-height: 100vh;
  color: white;
}

.thinHeading{
  font-weight: 300;
}

#uploadFilesSampleTableContainer{
  width: 100%;
  overflow-y: auto;
  /*margin-top: 24px;*/
  padding-bottom: 12px;
}

#uploadFilesSampleTable{
  /*overflow-x: scroll;*/
  width: auto;
}

#uploadFilesSampleTableContainer, #uploadFilesSampleTable{
  transform:rotateX(180deg);
  -moz-transform:rotateX(180deg); /* Mozilla */
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -ms-transform:rotateX(180deg); /* IE 9+ */
  -o-transform:rotateX(180deg); /* Opera */
}

.actionButton {
  /*box-shadow: 0 0 20px #eee;*/
  border-radius: 4px;
  border:none;
  /*background-size: 200% auto;*/
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
}

.actionButtonSmall {
  padding: 6px 8px 4px 8px;
  text-align: center;
  border-radius: 4px;
  border: none;
}

.icon-size-16px > span{
  font-size: 16px;
}

.actionButton:hover{
  /*box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);*/
  /*transform: scale(1.05) perspective(1px)*/
}

.iconBtn{
  margin-bottom: -8px;
}

.imgAvatar{
  /*width: inherit;*/
  height: inherit;
  border-radius: 50%;
}

.quickStatsBox{
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .quickStatsBox{
    padding-bottom: 48px;
    text-align: center;
  }
}

.quickStatsBox #quickStatsHeader{
  margin-bottom: 0;
  font-size: 20px;
}

.quickStatsBox #stat {
  font-size: 60px;
}

.heroButton {
  transition: 0.5s;
  color: white;
  /*box-shadow: 0 0 20px #eee;*/
  border-radius: 4px;
  border:none;
  width: 100%;
  background-size: 200% auto;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 18px;
  z-index: 99;
}

.heroButtonNavbar {
  transition: 0.5s;
  color: white;
  /*box-shadow: 0 0 20px #eee;*/
  border-radius: 4px;
  border:none;
  width: 100%;
  background-size: 200% auto;
  padding: 4px 8px 4px 8px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  /*font-size: 18px;*/
}

.listItem{
  /*text-indent:em;*/
  list-style-type:disc;
  display:list-item;
  margin-left: 2em;
}

.heroUploadButton{
  background-image: linear-gradient(to right, #DC2424 0%, #4A569D  51%, #DC2424  100%);
}

.heroContactButton{
  background-image: linear-gradient(to right, #000000 0%, #000000  51%, #dc2424 100%);
}

.mobileSidebarLogoutButton{
  background-color: orange;
}

.heroButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.heroButtonNavbar:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 768px){

  .productCard{
    margin: 25px;
    text-align: center;
    padding: 50px;
    /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 30% 50% 20%;
    grid-template-areas:
            "logo ."
            "desc desc"
            "visit visit";
    height: auto;
  }
}


/*#heroSection > h1{*/
/*  color: gray;*/
/*}*/

/*#heroSection > h4{*/
/*  margin-top: -20px;*/
/*  font-size: 150%;*/
/*  color: black;*/
/*}*/

.productCardLink{
  text-decoration: none;
  cursor: pointer;
}

.productLogo > img{
  height: 72px;
  width: auto;
}

@media only screen and (max-width: 641px) {
  #productIndex{
    padding: 100px 10px 25px 10px;
  }
}

@media only screen and (min-width: 641px) {
  #productIndex{
    padding: 150px 50px 150px 50px;
  }
}


#productGrid{
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto auto;
  grid-template-areas:
          "api clean ."
          ". split merge";
}

#dbCard{
  grid-area: db;
  background-color: rgba(255,174,106,0.5);
}

#mlCard{
  grid-area: ml;
  background-color: rgba(225,173,1,0.5);

}
#mergeCard{
  grid-area: merge;
  background-color: rgba(128,0,128,0.2);
}

#splitCard{
  grid-area:split;
  background-color: rgba(225,158,255,0.5);
}

#cleanCard{
  grid-area: clean;
  background-color: rgba(208,226,255,0.5);
}

#apiCard{
  grid-area: api;
  background-color: rgba(208,228,196,0.5);
}
#dbVideo{
  grid-area: dbVideo;
  height: 80%;
  width: 80%;
  margin: 0 auto;
}

#mergeVideo{
  grid-area: mergeVideo;
  height: 80%;
  width: 80%;
  margin: 0 auto;
}
#cleanVideo{
  grid-area: cleanVideo;
  height: 80%;
  width: 80%;
  margin: 0 auto;
}
#apiVideo{
  grid-area: apiVideo;
  height: 80%;
  width: 80%;
  margin: 0 auto;
}

.productCard{
  cursor: pointer;
  /*border: 1px lightgrey solid;*/
  /*border-radius: 15px;*/
  /*background-color: whitesmoke;*/
  margin: 15px;
  text-align: center;
  padding: 30px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 30% 50% 20%;
  grid-template-areas:
          "logo ."
          "desc desc"
          "visit visit";
  height: auto;
}

.card{
  padding-top: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-bottom: 15px;
}

.productLogo{
  grid-area: logo;
  text-align: left;
  cursor: pointer;
}

.productTitle{
  grid-area: title;
  color: black;
  text-align: left;
  padding-top: 15px;
  /*padding-left: 5px;*/
}

.productDesc{
  padding-top: 40px;
  text-align: left;
  grid-area: desc;
  font-size: 100%;
  font-weight: bold;
  font-family: "Museo Sans Rounded", sans-serif;
  cursor: pointer;
  /*margin-bottom: -40px;*/
}

.productVisit{
  grid-area: visit;
  text-align: left;
}

.productVisit > a {
  text-decoration: none;
  color: #0081ff;
  font-size: 125%;
  text-align: left;
}

/*#contactUsFormDiv{*/
  /*display: flex;*/
  /*justify-content: center;*/
  /*align-items: center;*/
  /*text-align: center;*/
  /*padding-top: 30px;*/
  /*padding-bottom: 30px;*/
/*}*/

/*#contactUsForm{*/

/*}*/

.spreadsheetNumber{
  padding: 50px;
  text-align: center;
}

.spreadsheetNumber b{
  font-size:35px;
  color: blueviolet;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: inherit;
  height: inherit;
  /*width: 120px;*/
  /*height: 120px;*/
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.uploadOuterSection{
  background-color: #b20000;
  /*background-color: #99e099;*/
  color: white;
  /*border-radius: 5px;*/
  height: 200px;
  /*padding-top: 1%;*/
  /*padding-left: 1%;*/
  padding: 5px;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*resize: vertical;*/
  /*overflow: auto;*/
  margin-bottom: 2.5%;
  cursor: pointer;
}

.uploadInnerSection{
  /*border: 1px dashed #7F7F7F;*/
  border: 1px dashed white;
  /*border: 2px dashed whitesmoke;*/
  /*background-color: #009900;*/
  background-color: #4c0000;
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*resize: vertical;*/
  /*overflow: auto;*/
}

.uploadLabel{
  padding-top: 50px;
  height: 100%;
  width: 100%;
  text-align: center;
}

.uploadIcons{
  font-size: 250%;
  font-weight: 300;
}


@media only screen and (max-width: 641px) {
  .newsletterDesc{
    color: darkgray;
    font-style: italic;
    text-align: center;
  }

  #newsletterInput{
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }

  #newsletterSignUp{
    padding: 50px 10px 50px 10px;
  }
}

#newsletterInput > span{
  padding-bottom: 10px;
}

@media only screen and (min-width: 641px) {
  #newsletterSignUp{
    padding: 100px 0px 100px 0px;
    margin: 0 auto;
    width: 50%;
    text-align: center;
  }

  .newsletterDesc{
    color: darkgray;
    font-style: italic;
    text-align: center;

  }

  #newsletterInput{
    width: 100%;
    margin: 0 auto;
  }
}

.inputDiv{
  padding-bottom: 30px;
}

.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  margin-left: 23px;
}

.input-container > input{
  height: 50px;
  /*width: 100%;*/
}

.icon {
  padding: 10px;
  background: red;
  color: white;
  min-width: 25px;
  text-align: center;
}

.input-field {
  width: 500px;
  height: 15px;
  font-size: 125%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.input-text-div{
  padding-bottom: 25px;

}
.customers{
  margin:100px;
  /*width: 1366px;*/

}

.customers img{
  /*height: 100px;*/
  /*width: 100px;*/
  /*padding: 20px;*/

}
.input-text-div > textarea{
  width: 550px;
  height: 150px;
  font-size: 125%;
  padding: 10px;
}

.btn{
  padding: 10px 20px 10px 20px;
  font-size: 110%;
  border: none;
  opacity: 0.85;
  cursor: pointer;
}

.btn:hover{
  opacity: 1;
}

#signUpButton{
  background-color: red;
  color: white;

}

#signUpButtonDiv{
  margin: 0 auto;
  text-align: center;
}

#contactUsButton{
  background-color: red;
  padding: 10px 20px 10px 20px;
  font-size: 110%;
  border: none;
  opacity: 0.85;
  cursor: pointer;
  color: white;
}

#contactUsButton:hover{
  opacity: 1;
}

.footerIndex{
  background-color: #06253B;
  /*background-color: #F5F5F5;*/
  /*color: black;*/
  /*width: 100%;*/
  color: white;
  padding: 20px 10px 20px 10px;
  /*margin-bottom: -5px;*/
  margin-top: 150px;
}

.linkStyle{
  color: cornflowerblue;
  cursor: pointer;
  text-decoration: none;
}

.supportFormLabel{
  font-size: 14px;
  font-weight: 700;
}

.inputError{
  color: red
}

#subjectCharLeft{
  color: gray;
  font-style: italic;
}

.buttonRaw {
  border: none;
  padding: 5px 8px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

#supportUploadFiles{
  background-color: #0088ff;
  color: white;
}

#supportFormClearAllButton{
  float: right;
  border-radius: 12px;
  font-size: 80%;
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.requiredLabel{
  padding-left: 3px;
  padding-right: 3px;
  color: red;
}

.circleBg{
  background: #F0F0F0;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  color: #6e6e6e;
  display: inline-block;
  font-weight: bold;
  line-height: 40px;
  margin-right: 5px;
  text-align: center;
  width: 40px;
}

.linkStyle:hover{
  text-underline: blue;
  color: blue;
}

.linkStyleGreen{
  color: #6FBB98;
  cursor: pointer;
}

.linkStyleGreen:hover{
  text-underline: #42705b;
  color: #42705b;
}

.semanticBorder{
  margin: 0em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  -webkit-transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease;
  transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.8);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
}

.menuDiv{
  padding: 16px;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
}

#menuSegmentSelected{
  transform: scale(1.02);
}

#menuSegmentSelected {
  color: #6FBB98;
}

.menuSegment{
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 12px 4px 12px 4px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #EE685F;
  transition: transform .2s; /* Animation */
}

.menuSegment:hover{
  cursor: pointer;
  transform: scale(1.02);
}

.menuSegment:hover{
  color: #EE685F;
}

.deleteLinkStyle{
  color: #C41E3A;
  cursor: pointer;
}

.deleteLinkStyle:hover{
  text-underline: red;
  color: red;
}

#footerLink{
  text-decoration: none;
}

#upgradeBgText{
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  /*border: 3px solid #f1f1f1;*/
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  padding: 48px;
  /*width: 100%;*/
  margin-left: 120px;
  text-align: center;
}

#upgradePageBg{
  background-image: url("./Illustrations/DisabledResults.svg");
  min-height: 100vh;
  background-repeat: no-repeat;
  filter: blur(8px);
  -webkit-filter: blur(8px);

}

#privacyPolicyDiv, #termsDiv{
  padding-left: 30px;
  padding-right: 30px;
}

.privacyRow, .termsRow{
  padding-bottom: 30px;
}

#contactUsButtonIndex{
  padding-top: 50px;
  text-align: center;
}

#contactUsButtonIndex > button{
  font-size: 200%;
}

.newsletterButton{
  background-image: linear-gradient(to right, #4b6cb7 0%, #182848  51%, #4b6cb7  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  /*display: block;*/
  border: none;
}

.newsletterButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


.ctaButton {
  background-image: linear-gradient(to right, #6441A5 0%, #2a0845  51%, #6441A5  100%);
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  /*display: block;*/
  border:none;
}

.ctaButton:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}


@media only screen and (max-width: 600px) {
  .input-field {
    width: 250px;
    height: 15px;
    font-size: 125%;
    /*padding: 10px;*/
    outline: none;
  }

  .input-text-div > textarea{
    width: 250px;
    height: 150px;
    font-size: 125%;
    /*padding: 10px;*/
  }
}

/*#navbarIcon > a > img{*/
/*  !*Full height: 293px*!*/
/*  height: 36px;*/
/*  !*Full width: 1884px*!*/
/*  width: auto;*/
/*  padding: 15px 15px 5px 15px;*/
/*  cursor: pointer;*/
/*}*/

.mobileNavLink{
  color: white;
  display: block;
  text-decoration: none;
  font-size: 20px;
  font-weight: 700;
  padding:16px;
  border-bottom: 1px solid #F0F0F0;
  cursor: pointer;
  /*padding-right: 20px;*/
}

#productMenuRow:hover{
  background: #F9F9F9;
  border-radius: 8px;
}

@media only screen and (min-width: 641px){
  .navbar{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
  }

}

.navLink{
  font-size: 16px;
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.navLink:hover{
  color: gray;
}


.browserFooter{
  text-align: center;
  padding: 25px 0 5px 0;
  /*display: grid;*/
  /*grid-template-columns: auto auto;*/
  /*grid-template-rows: auto;*/
  /*grid-template-areas: "socialmedia copyright";*/
}

.footerCopyright{
  /*grid-area: copyright;*/
  /*padding-top: 15px;*/

}

.footerSocialMedia{
  grid-area: socialmedia;
  text-align: right;
}

.footerIcon {
  padding: 10px;
  font-size: 15px;
  width: 15px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 50%;
}

.fa-facebook {
  background: #3B5998;
  color: white;
}

.fa-twitter {
  background: #55ACEE;
  color: white;
}

.fa-linkedin {
  background: #007bb5;
  color: white;
}

.fa-envelope{
  color: white;
  background: darkred;
}


.main{
  min-height: 100vh;
  /*display: flex;*/
  /*flex-direction: column;*/
}

.emailInput{
  height: 125%;
  width: 50%;
  font-size: 150%;
  padding: 10px;
  margin: 10px;
}

#blogHero {
  color: white;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(24deg, rgba(106, 210, 252, 1) 10%, rgba(181, 0, 210, 1) 92%);
  padding-bottom: 30px;
  padding-top: 30px;
}

#aboutHero {
  color: white;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(24deg, #7fd3fd 10%, #0081ff 92%);
  padding-bottom: 30px;
  padding-top: 30px;
}

#contactHero {
  color: white;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(24deg, rgb(255,0,0)	 10%, rgb(0,0,0) 92%);
  padding-bottom: 30px;
  padding-top: 30px;
}

@media only screen and (max-width: 641px) {
  .blogPictureImg{
    width: 350px;
    height: 250px;
    margin-left: 10px;
  }

  .blogDetailHeader{
    text-align: center;
  }

  .blogDetailDesc{
    font-size: 120%;
    margin-top: 10px;
    color: gray;
    word-wrap: break-word;
    text-align: center;
  }

  .blogDetailLink{
    font-size: 150%;
    text-decoration: none;
    text-align: center;
  }

  .blogDetailLinkP{
    text-align: center;
  }
}

@media only screen and (min-width: 641px){
  .blogIndex{
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-template-areas: "blogMenu blogDetail";
  }

  .blogPictureImg{
    width: 500px;
    height: 300px;
  }

  .blogMenu{
    grid-area: blogMenu
  }

  .blogDetail{
    grid-area: blogDetail
  }

  .blogDetailDiv{
    padding-bottom: 100px;
    margin-top: -10px;
    margin-right: 50px;
  }

  .blogDetailDesc{
    font-size: 120%;
    margin-top: 10px;
    color: gray;
    word-wrap: break-word;
  }

  .blogDetailLink{
    font-size: 150%;
    text-decoration: none;
    /*text-align: center;*/
  }
}

#blogMenuHeader {
  text-align: center;
  padding-bottom: 10px;
}

#blogMenuDiv {
  padding-left: 50px;
  padding-bottom: 50px;
}

#blogItemDiv {
  margin-bottom: 25px;
  cursor: pointer;
  width: 250px;

}

.cardDiv{
  /*border: 1px black solid;*/
  margin: 15px;
  text-align: center;
  padding: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.closeIconClass{
  height: 25px;
  width: 25px;
  float: right;
  margin: 0 auto;
  cursor: pointer;
  text-align: center;
}

.closeIconClass:hover{
  background-color: #DCDCDC;
  border-radius: 50%;
}

.closeIconClass > i{
  padding: 0;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}

@media only screen and (min-width: 641px) {
  #productQualityGrid {
    display: grid;
    grid-template-columns: 33% 33% 34%;
    grid-template-rows: auto auto;
    row-gap: 35px;
    grid-template-areas: "codeFree quality security" "noInstall design improving";
  }
}

@media only screen and (max-width: 641px){
  #productQualityGrid {
    display: grid;
    grid-template-columns: 100%;
    row-gap: 35px;
    grid-template-rows: auto auto auto auto auto auto;
    grid-template-areas: "codeFree" "quality" "security" "noInstall" "design" "improving";
  }

  .aboutDesc {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.commonIndexMenuItem, .commonIndexMenuItemSelected{
  text-align: center;
  font-size: 14px;
  padding: 12px;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  /*font-weight: 700;*/
}

.commonIndexMenuItem{
  border: 1px solid #F0F0F0;
}

.commonIndexMenuItem:hover{
  box-shadow: 0px 2px 10px rgba(2, 34, 19, 0.06);
  color: white;
  background-color: gray;
}

.commonIndexMenuItemSelected{
  color: white;
}

.commonIndexMenuItemSelected{
  background-color: gray;
}

@media only screen and (min-width: 641px) {
  .aboutDesc {
    margin: 0 auto;
    width: 50%;
  }
}

#codeFree{
  grid-area: codeFree
}

#quality{
  grid-area: quality
}

#security{
  grid-area: security
}

#noInstall{
  grid-area: noInstall
}

#design{
  grid-area: design
}

#improving{
  grid-area: improving
}

.productQualityFeatureDiv{
  margin: 0 auto;
  width: 65%;


}

.productQuality{
  background-color: rgba(208,226,255,0.25);
  padding: 50px 25px 50px 25px;
}

.aboutHeader{
  text-align: center;
  margin-top: 48px;
  font-size: 32px;
  font-weight: 700;
  color: #5E645E;
}

@media only screen and (min-width: 641px) {
  .aboutPic{
    height: 300px;
    width: 300px;
  }

  .aboutPicDiv{
    margin: 0 auto;
    width: 50%;
  }
}

@media only screen and (max-width: 641px) {
  .aboutPicDiv {
    margin: 0 auto;
    width: 50%;
  }

  .aboutPic {
    height: 200px;
    width: 200px;
  }
}

.productCard:hover{
  box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);
}

.hover:hover{
  box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);
}

.indexHeading{
  text-align: center;
  color: gray;
}

#automateService{
  grid-area: automateService;
}

#cleanService{
  grid-area: cleanService;
}

#geocodeService{
  grid-area: geocodeService;
}


@media only screen and (max-width: 641px) {
  .servicesCardDiv{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    width: 80%;
    text-align: center;
    margin-top: 15px;
    text-decoration: none;
    color: black;
    background-color: white;
  }

  #servicesCardGrid > div {
    margin: 0 auto;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 641px) {
  #servicesCardGrid{
    display: grid;
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: auto;
    grid-template-areas: "automateService cleanService geocodeService";
    /*grid-column-gap: 15px;*/
  }

  .servicesCardDiv{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
    width: 80%;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: white;
  }

  #servicesCardGrid > div {
    margin: 0 auto;
  }
}

.servicesCardIllustration > svg{
  width: 30%;
  height: 30%;
}

.servicesCardDiv:hover{
  box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);
}

#servicesPricing{
  text-align: center;
  padding-top: 15px;
}

.servicesCardLink{
  text-decoration: none;
}
.servicesCardLink:link {
  text-decoration: none;
}

.servicesCardLink:visited {
  text-decoration: none;
}

.servicesCardLink:hover {
  text-decoration: none;
}

.servicesCardLink:active {
  text-decoration: none;
}

@media only screen and (max-width: 641px) {
  #servicesProblemDisplay {
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 80%;
    margin: 0 auto;
    margin-top: 25px;
    padding: 15px;
  }

  #servicesProblemMenu {
    border: 1px solid lightgrey;
    width: 75%;
    border-radius: 5px;
    margin: 0 auto;
    background-color: whitesmoke;
  }

  #servicesProblemIndex {

  }

  #servicesHeader{
    margin-left: 10px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 641px) {
  #servicesProblemDisplay {
    grid-area: problemDisplay;
    border: 1px solid lightgrey;
    border-radius: 5px;
    width: 80%;
    padding: 15px;
  }

  #servicesProblemMenu {
    margin: 0 auto;
    border: 1px solid lightgrey;
    width: 75%;
    border-radius: 5px;
    grid-area: problemMenu;
    background-color: whitesmoke;
  }

  #servicesProblemMenuAndDisplay {
    display: grid;
    grid-template-columns: 300px auto;
    grid-template-rows: auto;
    grid-template-areas: "problemMenu problemDisplay";
  }

  #servicesHeader{
    /*margin-left: 40px;*/
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
}

.servicesMenuLink{
  padding: 15px;
  background-color: whitesmoke;
  cursor: pointer;
  font-weight: bold;
  font-size: 85%;
}
.tutorialsPreviewDiv{
  margin-right: 50px;
  margin-bottom: 250px;
  text-align: center;
}

.useCaseCard{
  font-family: museo sans rounded, "sans-serif";
  padding: 25px 10px 25px 10px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-top: 25px;
  border-radius: 10px;
  background-color: whitesmoke;
  position: relative;
  margin-left: 40px;
  width:auto;
  height:100%;
  /*cursor: pointer;*/
}

.useCaseCard:hover{
  box-shadow: 0 8px 32px 0 rgba(0,0,0,0.2);
}

.useCaseCardImg{
  /*height: 50%;*/
  width: 50%;
  height: 150px;
  margin: 0 auto;
  padding-top:25px;
}
.ui.avatar.image, .ui.avatar.image img, .ui.avatar.image svg, .ui.avatar.images .image, .ui.avatar.images img, .ui.avatar.images svg {
  margin-right: .25em;
  display: inline-block;
  width: 2em;
  height: 2em;
  border-radius: 500rem;
}
.useCaseCard writtenBy{
  width: 30px;
}


.useCaseCardBg{
  background-color: orangered;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: -40px;
  position: relative;
  /*position: absolute;*/
}

/*.useCaseCardBg1{*/
/*  background-image: linear-gradient(to right, green, greenyellow, yellow);*/
/*}*/

/*.useCaseCardBg2{*/
/*  background-image: linear-gradient(to bottom, greenyellow, yellow, green);*/
/*}*/

/*.useCaseCardBg3{*/
/*  background-image: linear-gradient(to bottom right, yellowgreen, greenyellow, green);*/
/*}*/

#activeMenuLink{
  color: white;
  background-color: red;
}

#guidesIndex{
  min-height: 100vh;
}

.segment{
  background: #fff;
  border-radius: 2px;
  padding: 5px;
}

.segment-raised{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.step {
  background: #cccccc;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
}

#servicesCalculateIndex{
  margin-top: 75px;
  padding-top: 10px;
  background-color: #990000;
  padding-bottom: 50px;
}

@media only screen and (max-width: 641px) {
  #servicesAdHoc{
    margin: 0 auto;
    text-align: center;
    width: 75%;
    margin-bottom: 25px;
    background-color: whitesmoke;
  }

  #servicesRecurring{
    margin: 0 auto;
    text-align: center;
    width: 75%;
    background-color: whitesmoke;
  }
}

@media only screen and (min-width: 641px) {
  #servicesCalculateTier{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: "servicesAdHoc servicesRecurring";
  }

  #servicesAdHoc{
    grid-area: servicesAdHoc;
    margin: 0 auto;
    text-align: center;
    width: 60%;
  }

  #servicesRecurring{
    grid-area: servicesRecurring;
    margin: 0 auto;
    text-align: center;
    width: 60%;
  }
}

.pricingNumber{
  font-size: 350%;
  color: darkblue;
  font-weight: 200;
}

.pricingCurrency{
  padding-bottom: 50px;
  font-size: 125%;
}

.pricingInterval{
  font-size: 125%;
}

#servicesFAQ{
  margin-top: 60px;
  margin-left: 10%;
  width: 80%;
}

#servicesFAQAccordionContainer{
  border: 1px solid lightgrey;
  background-color: whitesmoke;
}

.accordion{
  border: 1px solid #F0F0F0;
  background-color: white;
  border-radius: 4px;
}

.accordionHeader{
  cursor: pointer;
  font-size: 14px;
  color: #161E16;
  width: 100%;
  text-align: left;
  outline: none;
  border: none;
  border-radius: 4px;
  background-color: white;
  font-weight: 700;
  padding: 8px;
}

.accordionHeader:focus {outline:0;}

.accordionHeaderText{
  grid-area: accordionHeaderText;
  padding-left: 15px;}

.accordionHeaderIcon{
  grid-area: accordionHeaderIcon;
}

.accordionHeaderIcon > i {
  float: right;
  top: 50%;
}

.accordionBody{
  padding: 0 16px 16px 16px;
}

#resourcesIndex{
  padding-top: 150px;
  padding-left: 25px;
  padding-bottom: 50px;
  text-align: center;
}

.articleCard{
  margin: 25px;
  text-align: center;
  padding: 50px;
  /*box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);*/
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 30% 50% 20%;
  background-color: lightgrey;

  height: auto;

}

.articleCard img{
  height: 50px;
  width: 50px;
}

@media only screen and (min-width: 641px) {
  #resourcesGrid{
    display: grid;
    grid-template-areas: "summary blog video";
    grid-template-columns: 33% 34% 33%;
    grid-template-rows: auto auto;


  }

  #resourcesBlog{
    grid-area: blog;
    color: white;
  }

  #resourcesBlog > div{
    margin-top: 70px;
  }

  #resourcesHeader{
    color: white;
    grid-area: summary;
  }

  #resourcesVideo{
    grid-area: video;
  }

  #resourcesVideo > div{
    margin-top: 70px;
  }

  #resourcesHeaderTitle{
    font-size: 300%;
    padding-bottom: 7px;
  }

}

@media only screen and (max-width: 641px) {
  #resourcesHeader{
    color: white;
  }

  #resourcesBlog > div{
    color: white;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  #resourcesVideo > div{
    color: white;
    margin: 0 auto;
  }

  #resourcesHeaderTitle{
    font-size: 200%;
    padding-bottom: 7px;
  }
}


@media only screen and (max-width: 641px) {
  #servicesDisplayWorks{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 15px;
    background-color: whitesmoke;
    margin-bottom: 25px;
  }

  #servicesDisplayMisc{
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 15px;
    background-color: whitesmoke;
    margin-bottom: 25px;
  }

  #servicesDisplayDetails{
    padding: 15px;
    margin: 15px;
  }
}

@media only screen and (min-width: 641px) {
  #servicesDisplayDetails{
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
    grid-template-areas: "howServiceWorks servicesMiscInfo";
    padding-top: 25px;
  }

  #servicesDisplayWorks{
    grid-area: howServiceWorks;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 15px;
    margin: 15px;
    background-color: whitesmoke;
  }

  #servicesDisplayMisc{
    grid-area: servicesMiscInfo;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 15px;
    margin: 15px;
    background-color: whitesmoke;
  }
}

.servicesDisplayHeader{
  font-size: 150%;
}

#servicesDisplayDetailsHeader{
  font-size: 125%;
  font-weight: bold;
  text-align: center;
}

.bannerAlert {
  min-height: 80px;
  background: #FFFFFF;
  padding: 16px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: auto 1fr auto;
}

.bannerIcon{
  margin-right: 10px;
}

#navbarUpgradeBtn{
  font-weight: 700;
}

#navbarUpgradeBtn::after{
  /*content: ' Limited Time Offer';*/
  /*font-size: 12px;*/
}

#upgradeModalHeading::after{
  content: 'Offer valid till Apr 1';
  font-size: 16px;
  vertical-align: top;
  background-color: #0081ff;
  padding: 0px 4px 0px 4px;
  border-radius: 4px;
  color: white;
  margin-left: 4px;
}

.alpha::after {
  content: 'ALPHA';
  font-size: 12px;
  color: white;
  background-color: #EE685F;
  display: inline-block;
  vertical-align: bottom;
  /*margin-bottom: 13px;*/
  padding: 0 2px 0 2px;
  font-weight: 700;
  border-radius: 2px;
  margin-top: -4px;
}

.navbarLogo::after {
  /*content: 'BETA';*/
  /*font-size: 12px;*/
  /*color: white;*/
  /*background-color: purple;*/
  /*display: inline-block;*/
  /*vertical-align: bottom;*/
  /*margin-bottom: 13px;*/
  /*padding: 0 2px 0 2px;*/
  /*font-weight: 700;*/
  /*border-radius: 2px;*/
}

.inputGray{
  background: #F0F0F0;
  border-radius: 4px;
  padding: 6px 8px;
  border: none;
  outline: none;
  color: #161E16;
}

.inputGray::placeholder{
  color: #BCBCC2;
}

.tutorialPopup{
  z-index: 500;
  position: fixed;
  bottom: 3%;
  left: 3%;
}

.tutorialPopupButton{
  background-color: #EE685F;
  color: white;
  font-weight: bold;
  padding: 16px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  cursor: pointer;
  min-width: 240px;
  /*height: 100px;*/
  /*width: 50px;*/
}

.numberCircle{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 4px;
  text-align: center;
  font: 16px Arial, sans-serif;
}

.hoverShadow:hover{
  box-shadow: 0 16px 32px 0 rgba(0,0,0,0.4);
}

.sampleDataTable{
  overflow-x: scroll;
  width: 100px;
  /*width: 100%;*/
  /*display: block;*/
}

.customTooltip {
  position: relative;
  display: block;
}

.customTooltip .customTooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 130%;
  transition: opacity 1s;
}

.customTooltip:hover .customTooltiptext {
  visibility: visible;
    opacity: 1;
}

.ordersMenuItem{
  border: 1px solid #e8e8e8;
  padding: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.ordersMenuItem:hover{
  border: 1px solid #e8e8e8;
  padding: 16px;
  border-radius: 4px;
  background-color: #e8e8e8;
  /*color: white;*/
}

.ordersMenuItemSelected{
  border: 1px solid white;
  padding: 8px;
  border-radius: 4px;
  background-color: whitesmoke;
  color: black;
  cursor: default;
}

.menuSegment{
  display: grid;
  grid-template-columns: auto 1fr auto;
  padding: 12px 4px 12px 4px;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9696A0;
  transition: transform .2s; /* Animation */
  cursor: pointer;
}

.menuSegment:hover{
  cursor: pointer;
  transform: scale(1.02);
}

.menuSegment:hover{
  color: #EE685F;
}

#menuSegmentSelected {
  transform: scale(1.02);
  color: #EE685F;
}

#flowBoxes {
  margin:auto;
  /*padding:20px;*/
  /*min-width:700px;*/
}

#flowBoxes div {
  display:inline-block;
  position:relative;
  height:38px;
  line-height:38px;
  padding:0px 40px;
  border:1px solid #ccc;
  margin-right:2px;
  background-color:white;
  font-size: 16px;
  font-weight: 700;
}

#flowBoxes div.right:after{
  content:'';
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
  width:26px;
  height:26px;
  position:absolute;
  right:0;
  top:-1px;
  background-color:white;
  z-index:51;

  -webkit-transform: translate(14px,6px) rotate(45deg);
  -moz-transform: translate(14px,6px) rotate(45deg);
  -ms-transform: translate(14px,6px) rotate(45deg);
  -o-transform: translate(14px,6px) rotate(20deg);
  transform: translate(14px,6px) rotate(45deg);
}

#flowBoxes div.left:before{
  content:'';
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
  width:26px;
  height:26px;
  position:absolute;
  left:0;
  top:-1px;
  background-color:white;
  z-index:50;

  -webkit-transform: translate(-14px,6px) rotate(45deg);
  -moz-transform: translate(-14px,6px) rotate(45deg);
  -ms-transform: translate(-14px,6px) rotate(45deg);
  -o-transform: translate(-14px,6px) rotate(20deg);
  transform: translate(-14px,6px) rotate(45deg);
}
#flowBoxes .active{
  background-color:#EE685F;
  color:white;
}
#flowBoxes div.active:after{
  background-color:#EE685F;
}

.footerItem{
  cursor: pointer;
  text-decoration: none;
  display: block;
  margin-bottom: 12px;
  color: #161E16;
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.footerItem:hover{
  cursor: pointer;
  color: #5499F8;
  text-decoration: none;
}

.callToActionBtn{
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  font-family: Lato, sans-serif;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 14px;
}

.callToActionBtn > div {
  display: grid;
  grid-template-columns: auto auto;
}

.callToActionBtn > div > div:nth-of-type(1){
  text-align: left;
}

.callToActionBtn > div > div:nth-of-type(2){
  text-align: right;
}

.darkCTABtn{
  background: #5499F8;
  border-radius: 4px;
  color: white;
}

.lightgray{
  background-color: lightgrey;
}

.uploadOuterSection{
  border: 2px dashed #DEDEDE;
  /*background-color: #99e099;*/
  color: white;
  /*border-radius: 5px;*/
  min-height: 250px;
  /*padding-top: 1%;*/
  /*padding-left: 1%;*/
  /*padding: 5px;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: center;*/
  /*resize: vertical;*/
  /*overflow: auto;*/
  /*margin-bottom: 2.5%;*/
  cursor: pointer;
  background-color: white;
}

.uploadLabel{
  /*height: 100%;*/
  /*width: 100%;*/
  text-align: center;
  padding: 10px 23px;
  min-width: 120px;
  height: 40px;
  /*background: #6FBB98;*/
  border-radius: 4px;
  font-size: 14px;
  font-weight: 700;
  /*color: #FFFFFF;*/
  font-family: 'Lato', sans-serif;
  cursor: pointer;
}